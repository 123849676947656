.report {
    &__search-container {
        display: grid;
        grid-template-columns: 60% 40%;
        gap: 1em;
    }

    &__title {
        color: var(--primary-color);
        font-family: var(--font-medium);
        font-size: 0.85rem;
        place-self: flex-start;
    }

    &__box {
        width: 100%;
        background-color: white;
        margin: 2em 0 0;
        border: 1px solid var(--primary-color);
        padding: 0.5em 2em;
        min-height: 90%;
        height: auto;
        display: grid;
        grid-template-rows: 10% 80% 10%;
    }

    &__table {
        display: grid;
        grid-template-columns: 0.4fr 0.4fr 0.5fr 0.6fr 0.8fr 0.8fr 6rem;
        width: 100%;
        margin-top: 2em;
        align-items: center;
    }

    &__item {
        color: #091434;
        font-family: var(--font-medium);
        font-size: 0.85rem;
        justify-self: flex-start;
        align-self: center;
        text-align: left;
    }
    &__actions {
        display: flex;
        justify-content: flex-end;
        align-self: center;
    }

    &__footer {
        place-self: flex-end;
        width: clamp(12rem, 20%, 10rem);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__detail {
        &-title {
            font-size: 2rem;
            color: var(--primary-color);
            font-family: var(--font-medium);

            &__container {
                display: flex;
                justify-content: space-between;
                width: 15rem;
                align-items: center;
            }
        }

        &-box {
            width: 100%;
            background-color: white;
            margin: 2em 0 0;
            border: 1px solid var(--primary-color);
            padding: 2em 2em;
            height: auto;
            min-height: 80vh;
            max-height: 85vh;
            overflow-y: auto;
        }

        &-subtitle {
            font-size: 1.5rem;
            color: #363636;
            font-family: var(--font-medium);
        }

        &-main {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 1.5rem 0 0;
            margin-bottom: 2em;
            gap: 3em;
        }
    }
    &__question-container {
        display: flex;
        flex-direction: column;
        height: auto;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 1em 0 0;
    }

    &__element-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1em 0 0;
    }

    &__label {
        font-size: 1rem;
        color: #676767;
        font-family: var(--font-medium);
        text-transform: capitalize;
    }

    &__value {
        font-size: 1.1rem;
        color: #363636;
        font-family: var(--font-medium);
    }

    &__question {
        font-size: 1.1rem;
        color: var(--primary-color);
        font-family: var(--font-medium);
        text-align: left;
        margin-bottom: 1.25em;
    }

    &__score {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0 2em;
    }

    &__link {
        font-size: 1.1rem;
        color: var(--primary-color);
        font-family: var(--font-medium);
        text-decoration: underline;
    }

    &__download {
        margin-top: 5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        
        &-button {
            background-color: #024e85;
            padding: 1em;
            font-size: 1rem;
            width: 20%;
            color: white;
            font-family: var(--font-medium);
            border-radius: 0.5em;
            outline: none;
            border: 1px solid var(--primary-color);
            transition: var(--transition-bg), var(--transition-color),
                width 400ms ease-in-out;
            cursor: pointer;

            &:hover {
                color: var(--primary-color);
                background-color: white;
            }
        }
    }
}

.pagination {
    &__title {
        color: var(--primary-color);
        font-family: var(--font-medium);
        font-size: 1.1rem;
        place-self: center;
    }

    &__icon {
        width: 1rem;
    }
}
