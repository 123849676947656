.add__modal {
    top: 10%;
    left: 30%;
    right: 30%;
    bottom: 15%;
    overflow: auto;
    min-width: max-content;
    background-color: aliceblue;

    &__header {
        color: #1c1c1c;
        font-size: 1.5rem;
        font-family: var(--font-regular);
        margin: 0.1em 0;
        text-align: center;
    }

    &__content {
        min-width: 35rem;
        width: 80%;
        margin: 0 auto;

        & > .searchBox {
            margin: 2em 0;
            background-color: #ffffff;
            width: 100%;
        }
    }

    &__checkbox-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-column-gap: 2em;
        grid-row-gap: 1em;

        width: 100%;
    }

    &__button-container {
        width: 40%;
        margin: auto;

        & .button__container > button.button__primary {
            padding: 0.8em 0.2em;
            font-size: 1.1rem;
        }
    }

    &__manager-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        margin: 0 0 0.5em;
    }

    &__manager-label {
        font-size: 1.2rem;
        padding: 0;
        color: var(--accent-text);
    }

    &__input-container {
        display: grid;
        margin: 0;
        padding: 0;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1em;

        & .MuiInputBase-root {
            width: 15rem !important;
            min-width: 10rem !important;
            background-color: #ffffff;
            margin: 0;
        }
    }

    &__edit-button-container {
        margin-top: 3em;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > .button__container {
            width: 30%;

            & > button {
                font-size: 0.8rem;
                padding: 1em 0.5em;
            }
        }
    }
}

.container-box {
    height: 13rem;
    margin-bottom: 1em;
    overflow-y: auto;
}

.close__img-user {
    width: 2rem;
    height: 2rem;
}
