.dashboard {
    display: grid;
    grid-template-columns: minmax(12rem, 15%) 85%;
    grid-template-areas: "nav main";
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-y: hidden;

    &__nav {
        grid-area: nav;
        margin: 0;
        padding: 2em 0 0 2em;
        height: 100vh;
        width: 100%;
        background-color: #ffffff;
        overflow: hidden;

        //box-shadow: var(--shadow);
    }

    &__main {
        grid-area: main;
        margin: 0;
        padding: 0;
        height: auto;
        width: 100%;
        display: grid;
        grid-template-columns: minmax(1px, 1fr) minmax(50rem, 25fr) minmax(
                1px,
                1fr
            );
        background-color: var(--secondary-color);
        overflow-y: auto;
    }
}
