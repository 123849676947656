.merchant {
    &__table {
        display: grid;
        grid-template-columns: 0.3fr 0.4fr 0.4fr 0.4fr 15rem;
        width: 100%;
        margin-top: 2em;
        align-items: center;
    }
    &__actions {
        display: flex;
        justify-content: space-between;
        align-self: center;
        padding: 0 2em;

        // & > a {
        //     color: var(--primary-color);
        //     font-size: 1.2rem;
        //     font-family: var(--font-medium);
        //     cursor: pointer;
        //     background-color: transparent;
        //     border: none;
        //     margin: 0;
        //     align-self: center;
        //     /* padding: 0 0 0 2em; */
        //     outline: none;
        //     align-self: center;
        //     width: 4rem;
        // }
    }
}

.code__modal {
    top: 3%;
    left: 30%;
    right: 40%;
    bottom: 3%;
    overflow: auto;
    min-width: max-content;
    background-color: aliceblue;
}
