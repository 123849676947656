.price__modal {
    top: 30%;
    left: 30%;
    right: 30%;
    bottom: 30%;
    overflow: auto;
    min-width: max-content;
    background-color: aliceblue;

    &__header {
        color: #1c1c1c;
        font-size: 1.5rem;
        font-family: var(--font-regular);
        margin: 0.1em 0;
        text-align: center;
    }

    &__content {
        min-width: 35rem;
        width: 80%;
        margin: 0 auto;

        & > .searchBox {
            margin: 2em 0;
            background-color: #ffffff;
            width: 100%;
        }
    }

    &__button-container {
        width: 40%;
        margin: auto;

        & .button__container > button.button__primary {
            padding: 0.8em 0.2em;
            font-size: 1.1rem;
        }
    }
}

.close__img-user {
    width: 2rem;
    height: 2rem;
}
