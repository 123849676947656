.contact {
    &__table {
        display: grid;
        grid-template-columns: 0.6fr 1fr 1fr 10rem;
        width: 100%;
        margin-top: 2em;
        align-items: center;
    }

    &__item {
        color: #091434;
        font-family: var(--font-medium);
        font-size: 0.9rem;
        justify-self: flex-start;
        align-self: center;
        text-align: left;
        width: 90%;
        overflow-wrap: break-word;
    }

    &__label {
        color: var(--primary-color);
        font-size: 1.3rem;
        margin-top: 1em;
    }

    &__title {
        margin-top: 1em;
        font-size: 1.1rem;
    }

    &__detail {
        width: 75%;
        max-width: 35rem;
        margin: 0 auto;
    }
}
