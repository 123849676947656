.button__container {
    width: 100%;
    // margin-top: 1em;
    display: flex;
    justify-content: center;
}

.button__primary {
    width: 100%;
    cursor: pointer;
    padding: 1em 2em;
    font-family: var(--font-medium);
    border-radius: 0.8em;
    font-size: 1.3rem;
    outline: none;
    transition: var(--transition-bg), var(--transition-color);
    border: 1px solid var(--primary-color);

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }

    &-active {
        background-color: var(--primary-color);
        color: white;

        &:hover:not(:disabled) {
            background-color: transparent;
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
        }
    }

    &-unselected {
        background-color: transparent;
        color: var(--primary-color);
        border: 1px solid var(--primary-color);

        &:hover:not(:disabled) {
            background-color: var(--primary-color);
            color: white;
        }
    }
}
