.Input {
    background-color: #ffffff;
    border: 1px solid #b7b7b7;
    width: 100%;
    font-size: 1rem;
    padding: 0.8em 1em;
    margin: 0;
    outline: none;
    font-family: var(--font-medium);

    &:disabled {
        cursor: not-allowed;
        opacity: 0.9;
        color: #b4b4b4;
    }

    &:focus,
    :active {
        border: 1px solid var(--primary-color);
    }
}

//input:focus, textarea:focus {
//	border: 1px solid #2c2c2c;
//}

.input-dangerous > * {
    padding: 0 !important;
}
