.login {
    width: 100%;
    height: 100%;
    display: grid;
    background-color: #ffffff;
    grid-template-columns: minmax(10px, 1fr) minmax(60%, 800px) minmax(
            10px,
            1fr
        );

    &__main {
        grid-column: 2/3;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__logo {
        width: 8rem;
        height: 10rem;
        padding-bottom: 2em;
    }

    &__customer {
        grid-column: 3/3;
    }

    &__title {
        font-size: 2.5rem;
        margin-bottom: 1em;
        font-family: var(--font-regular);
        color: #000000;
    }

    &__subtitle {
        font-size: 2rem;
        color: var(--primary-color);
        margin-bottom: 1.5em;
    }

    &__input {
        width: 50%;
        line-height: 2;
        border: none;
        border-bottom: 1px solid #c5c5c5;
        background-color: transparent;
        color: var(--accent-text);
        font-size: 1.4rem;
        outline: none;
        font-family: var(--font-regular);
        margin-bottom: 2em;

        &::placeholder {
            font-size: 2rem;
            color: var(--accent-text);
            font-family: var(--font-regular);
        }

        &[type="password"] {
            font-size: 2rem;
            margin-bottom: 2rem;
            line-height: 1.4;
        }
    }

    &__button {
        width: 100%;
        cursor: pointer;
        padding: 0.8em 2em;
        font-family: var(--font-regular);
        font-size: 1.2rem;
        border-radius: 0.3em;
        letter-spacing: 0.3em;
        margin-top: 2em;
        outline: none;
        transition: var(--transition-bg), var(--transition-color);
        color: #ffffff;
        background-color: var(--primary-color);
        border: none;

        &:disabled {
            opacity: 0.6;
            cursor: not-allowed;
        }

        &:hover:not(:disabled) {
            background-color: transparent;
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
        }
    }
}
