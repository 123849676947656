.user {
    &__search-container {
        display: grid;
        grid-template-columns: 60% 40%;
        gap: 1em;
    }

    &__box {
        width: 100%;
        background-color: white;
        margin: 2em 0 0;
        border: 1px solid var(--primary-color);
        padding: 0.5em 2em;
        min-height: 92.5%;
        overflow-y: auto;
        height: auto;
        display: grid;
        grid-template-rows: 10% 90%;
    }

    &__main {
        padding: 1.5em 0;
    }
    &__table {
        display: grid;
        grid-template-columns: 0.3fr 0.5fr 0.9fr 0.6fr 0.8fr 6rem;
        width: 100%;
        margin-top: 2em;
        align-items: center;
    }

    &__item {
        color: #091434;
        font-family: "Visby-Medium", sans-serif;
        font-size: 0.95rem;
        justify-self: flex-start;
        align-self: center;
    }
    &__actions {
        display: flex;
        justify-content: flex-end;
        align-self: center;
    }

    &__footer {
        place-self: flex-end;
        width: clamp(12rem, 20%, 10rem);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__detail {
        &-title {
            font-size: 2rem;
            color: var(--primary-color);
            font-family: var(--font-medium);

            &__container {
                display: flex;
                justify-content: space-between;
                width: 15rem;
                align-items: center;
            }
        }

        &-box {
            width: 100%;
            background-color: white;
            margin: 2em 0 0;
            border: 1px solid var(--primary-color);
            padding: 2em 2em;
            height: auto;
            min-height: 60vh;
            max-height: 65vh;
            overflow-y: auto;
        }

        &-subtitle {
            font-size: 1.5rem;
            color: #363636;
            font-family: var(--font-medium);
        }

        &-main {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 1.5rem 0 0;
            margin-bottom: 2em;
            gap: 3em;
        }
    }

    &__element-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1em 0 0;
    }

    &__label {
        font-size: 1rem;
        color: #676767;
        font-family: var(--font-medium);
    }

    &__value {
        font-size: 1.1rem;
        color: #363636;
        font-family: var(--font-medium);
    }

    &__active {
        font-size: 1.1rem;
        color: var(--primary-color);
        font-family: var(--font-medium);
    }

    &__test {
        font-size: 1.1rem;
        color: #363636;
        margin-top: 1.4em;
        font-family: var(--font-medium);
    }
}

.pagination {
    &__title {
        color: var(--primary-color);
        font-family: "Visby-Medium", sans-serif;
        font-size: 1.1rem;
        place-self: center;
    }

    &__icon {
        width: 1rem;
    }
}
