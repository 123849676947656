.searchBox {
    display: grid;
    grid-template-columns: minmax(4rem, 1fr) 10fr;
    width: 100%;
    margin: 0 0;
    height: 2rem;
    padding: 0;

    &__icon {
        grid-column: 1/2;
        grid-row: 1/2;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.05rem solid var(--primary-color);
        border-right: none;
        border-radius: 0.25em 0 0 0.25em;
    }

    &__search {
        grid-column: 2/3;
        grid-row: 1/2;
        background-color: transparent;
        outline: none;
        border: 1px solid var(--primary-color);
        border-left: none;
        width: 100%;
        font-family: var(--font-medium);
        font-weight: 400;
        line-height: 2rem;
        color: var(--primary-color);
        font-size: 1rem;
        padding: 0.3em 1.5em 0.3em 0;
        margin: 0;
        border-radius: 0 0.25em 0.25em 0;
        transition: border 400ms ease-in-out;

        &::placeholder {
            margin: 0;
            color: var(--primary-color);
        }
    }
}
