.test {
    &__box {
        width: 60%;
        background-color: white;
        margin: 2em 0 0;
        border: 1px solid var(--primary-color);
        padding: 2em;
        min-height: 60vh;
        height: auto;
        display: grid;
        place-self: center;
    }

    &__table {
        display: grid;
        grid-template-columns: 0.4fr 1fr 10rem;
        width: 100%;
        margin-top: 2em;
        align-items: center;
    }

    &__title {
        font-size: 2rem;
        color: var(--primary-color);
        font-family: var(--font-medium);
        text-align: left;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        align-self: center;
    }

    &__header {
        display: grid;
        grid-template-columns: 50% 25% 25%;
        height: 7rem;
        align-items: center;
        grid-gap: 1em;
        padding-bottom: 3em;
    }

    &__detail {
        &-box {
            width: 100%;
            background-color: white;
            margin: 2em 0 0;
            border: 1px solid var(--primary-color);
            padding: 2em 3em;
            min-height: 80vh;
            height: auto;
            display: grid;
            place-self: center;
        }
    }

    &__item {
        display: grid;
        grid-template-columns: 0.1fr 1fr 10rem;
        margin-top: 2em;
    }
}
