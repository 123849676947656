.delete {
    top: 35%;
    left: 30%;
    right: 40%;
    bottom: 35%;
    overflow: auto;
    min-width: max-content;
    background-color: aliceblue;

    &__header {
        color: #1c1c1c;
        font-size: 1.5rem;
        font-family: var(--font-regular);
        margin: 0.1em 0;
        text-align: center;
    }

    &__content {
        min-width: 35rem;
        width: 80%;
        margin: 0 auto;

        & > .searchBox {
            margin: 2em 0;
            background-color: #ffffff;
            width: 100%;
        }
    }
}

.container-box {
    height: 13rem;
    margin-bottom: 1em;
    overflow-y: auto;
}

.close__img-user {
    width: 2rem;
    height: 2rem;
}
