.promo {
    &__box {
        // width: 80%;
        // background-color: white;
        // margin: 2em 0 0;
        // border: 1px solid var(--primary-color);
        // padding: 0.5em 2em;
        // min-height: 75vh;
        // overflow-y: auto;
        // height: auto;

        width: 100%;
        background-color: white;
        margin: 2em 0 0;
        border: 1px solid var(--primary-color);
        padding: 2em;
        min-height: 75vh;
        height: auto;
        display: grid;
    }

    &__table {
        display: grid;
        grid-template-columns: 0.6fr 0.6fr 0.6fr 0.6fr 0.6fr 10rem;
        width: 100%;
        margin-top: 2em;
        align-items: center;
    }

    &__item {
        color: #091434;
        font-family: var(--font-medium);
        font-size: 0.9rem;
        justify-self: flex-start;
        align-self: center;
        text-align: left;
        width: 90%;
        overflow-wrap: break-word;
    }

    &__title {
        color: var(--primary-color);
        font-family: var(--font-medium);
        font-size: 1.2rem;
        place-self: flex-start;
        text-align: left;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-content: center;
        width: 100%;

        & > p {
            color: var(--primary-color);
            font-size: 2rem;
            font-family: var(--font-medium);
        }

        & > button {
            background-color: var(--primary-color);
            padding: 1em;
            font-size: 1rem;
            width: 10rem;
            color: white;
            font-family: var(--font-medium);
            border-radius: 0.5em;
            outline: none;
            border: 1px solid var(--primary-color);
            transition: var(--transition-bg), var(--transition-color),
                width 400ms ease-in-out;
            cursor: pointer;

            &:hover {
                color: var(--primary-color);
                background-color: white;
            }
        }
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        align-self: center;
    }
}
